jQuery(document).ready(function($) {
    var firstScrollDetected = false; // Flag to track the first scroll
    // FRONT PAGE JS
    //Fullpage initialisation
    $('#fullpage').fullpage({
        //options here
        // licenseKey: 'LG6M6-J458H-QNIOJ-MKE17-TYMQM',
        licenseKey: 'GMX3I-S2JOJ-DAI3J-Z1K48-KKVNM', // updated Tuesday 13 Feb 2024
        autoScrolling: true,
        // scrollHorizontallyKey: 'UTFjbTkxWjJVdFpHVjJNeTVqYjIwPTByX2pkdmMyTnliMnhzU0c5eWFYcHZiblJoYkd4NTQ3Vw==',
        // scrollHorizontallyKey: 'bE1kM0JsYm1kcGJtVXVZMjl0dmhfNGJJYzJOeWIyeHNTRzl5YVhwdmJuUmhiR3g1cjRX', STAGING
        scrollHorizontallyKey: 'ZDRZM0pwY0hCekxtZHNiMkpoYkE9PWRiXzhFSWMyTnliMnhzU0c5eWFYcHZiblJoYkd4NUdZbg==', // LIVE
        scrollHorizontally: true,
        scrollOverflow: false,
        anchors: ['hero-anchor', 'text-image-anchor', 'slider-anchor'],
        menu: '.fullpage-menu',
        controlArrows: false,
        scrollingSpeed: 700,
        fadingEffect: 'slides',
        // lockAnchors: true,
        credits: { enabled: false, label: 'Made with fullPage.js', position: 'right' },

        onSlideLeave: function(section, origin, destination, direction, trigger) {
            if (section.anchor == 'slider-anchor') {
                $('.slider-menu-item').each(function() {
                    $(this).removeClass('active');
                    if (this.dataset.menuanchor == destination.anchor) {
                        $(this).addClass('active');
                    }
                    transformMenu($('#slider-menu'));
                })
                $('.card--slider-home').each(function() {
                    $(this).removeClass('active');
                    if ($(this).data('index') == $('#slider-menu').find('.active').eq(0).data('index')) {
                        $(this).addClass('active');
                    }
                })
            }
        },
        onLeave: function(origin, destination, direction, trigger) {
            origin.item.classList.remove('animating');
            destination.item.classList.add('animating');
            // Check if this is the first scroll and the flag is false
            if (!firstScrollDetected) {
                // Fade out the cookie update notice on the first scroll
                $('.cookie-update-notice').fadeOut('slow');
                firstScrollDetected = true; // Update the flag so this only happens once
            }
        },
        // afterLoad: function(origin, destination, direction, trigger){
        // },

    });



    //methods
    $.fn.fullpage.setAllowScrolling(true, 'down, up');

    function load_hp_hero_video($slide) {

        if ($slide.length > 0) {

            if ($slide.find('.slide__background-video').length > 0) {

                $video_container = $slide.find('.slide__background-video');

                if ($video_container.data('src')) {

                    var ext = $video_container.data('src').split('.').reverse()[0]

                    if (ext == 'gif') {

                        var img = document.createElement('img');
                        img.src = $video_container.data('src');
                        img.alt = 'Cripps Sears animation';

                        $video_container.get(0).append(img);
                        $video_container.data('src', '');


                    }

                    if (ext == 'mp4') {

                        // create video element
                        var video = document.createElement('video');
                        var source = document.createElement('source');

                        source.src = $video_container.data('src');
                        source.type = "video/mp4";
                        video.append(source);
                        video.setAttribute('data-autoplay', "");
                        video.setAttribute('autoplay', '');
                        video.setAttribute('onloadeddata', 'this.play()');
                        video.controls = false;
                        video.muted = true;
                        video.loop = true;
                        // fix for iOS
                        video.setAttribute('playsinline', 'playsinline');
                        video.setAttribute('muted', '');

                        // insert video element
                        $video_container.html('');
                        $video_container.get(0).append(video);
                        $video_container.data('src', '');

                        $video_container.find('video').get(0).addEventListener('canplay', function(event) {
                            event.target.play();
                            //Remove event listener
                        });

                    }


                }

            }

        }

    }
    $('.homepage-hero .has-video').each(function() {
        load_hp_hero_video($(this));
    })

    // Hide the cookie notice when the close button is clicked
    $('.cookie-update-notice .close').click(function() {
        $('.cookie-update-notice').fadeOut('slow');
    });

    //Slider Section
    function transformMenu(menu, content) {
        $multiplier = menu.children('.active').index();
        if (window.innerWidth > 1819) {
            menu.children('li').each(function() {
                $(this).css('transform', 'translateY(' + $multiplier * -70 + 'px)');
            })
        } else if (window.innerWidth > 767) {
            menu.children('li').each(function() {
                $(this).css('transform', 'translateY(' + $multiplier * -50 + 'px)');
            })
        } else if (window.innerWidth > 500) {
            menu.children('li').each(function() {
                $(this).css('transform', 'translateY(' + $multiplier * -45 + 'px)');
            })
        }
    }



    //Loading Screen
    function animate() {
        $('.loading-screen__logo').addClass('fading');
        // position();
        closeLoad();
    }

    function closeLoad() {
        setTimeout(function() {
            $('.loading-screen').addClass('hidden');
        }, 2000)
    }

    $(window).on('load', function() {
        setTimeout(function() {
            animate();
        }, 700);
    })


    //Link Arrows
    $('.link.link--arrow').each(function() {
        $(this).append('<?xml version="1.0" encoding="UTF-8"?><svg id="Group_20435" class="link--arrow__arrow" data-name="Group 20435" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="16.943" height="15.15" viewBox="0 0 16.943 15.15"><defs><clipPath id="clip-path"><rect id="Rectangle_4536" data-name="Rectangle 4536" width="16.943" height="15.15" fill="#ffffff"/></clipPath></defs><g id="Group_20433" data-name="Group 20433" clip-path="url(#clip-path)"><path id="Path_58598" data-name="Path 58598" d="M9.37,0A.837.837,0,0,0,9.3.068a.9.9,0,0,0,0,1.163l.01.011C11.607,3.534,12.87,5.3,13.1,7.026H.549a.549.549,0,0,0,0,1.1H13.1c-.212,1.719-1.46,3.489-3.747,5.776l-.035.036A.9.9,0,0,0,9.37,15.15l7.574-7.575Z" fill="#ffffff"/></g></svg>');
    })


    var shown_animation = getCrippsSearsCookie('crippssears-homepage-animation');


    if (!shown_animation || shown_animation == undefined || shown_animation == null) {


        setTimeout(function() {
            animate();
        }, 700);

        var date = new Date().toISOString();

        setCrippsSearsCookie('crippssears-homepage-animation', date, '');

    } else {


        $('.loading-screen__logo').removeClass('positioned');
        $('.loading-screen .card').addClass('hidden');

        setTimeout(function() {
            $('.loading-screen').addClass('hidden');
        }, 700)

    }


    function setCrippsSearsCookie(name, value, daysToLive) {

        // Encode value in order to escape semicolons, commas, and whitespace
        var cookie = name + "=" + encodeURIComponent(value);

        if (typeof daysToLive === "number") {
            /* Sets the max-age attribute so that the cookie expires
            after the specified number of days */
            cookie += "; max-age=" + (daysToLive * 24 * 60 * 60);


        } else {

            cookie += "; max-age=";

        }

        document.cookie = cookie;

    }

    function getCrippsSearsCookie(name) {
        // Split cookie string and get all individual name=value pairs in an array
        var cookieArr = document.cookie.split(";");

        // Loop through the array elements
        for (var i = 0; i < cookieArr.length; i++) {
            var cookiePair = cookieArr[i].split("=");

            /* Removing whitespace at the beginning of the cookie name
            and compare it with the given string */
            if (name == cookiePair[0].trim()) {
                // Decode the cookie value and return
                return decodeURIComponent(cookiePair[1]);
            }
        }

        // Return null if not found
        return null;
    }

});